* {
  --primary-color: rgba(57, 167, 242);
  --accent-color: rgba(45, 128, 184);
  --background-color: rgba(30, 30, 30);
  --secondary-color: rgba(37, 37, 37);
  --tertiary-color: rgba(60, 60, 60);
  --tertiary-accent-color: rgba(70, 70, 70);
  --text-accent-color: rgba(90, 201, 131);
  --text-second-accent: rgba(226, 192, 128);
  --text-tertiary-accent: rgba(123, 123, 123);
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  box-sizing: border-box;
  color: white;
  font-family: Arial, sans-serif;
  background: var(--background-color);
}

a,
li {
  text-decoration: none;
  color: inherit;
  display: inline-block;
}

.app--container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

h1 {
  padding-bottom: 2rem;
}

h2 {
  color: var(--text-tertiary-accent);
  padding-bottom: 2rem;
  font-size: 1rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  color: var(--primary-color);
  padding-bottom: 1rem;
}

/* 
Arrow Button 
*/

.scroll-down {
  position: absolute;
  bottom: 5rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mouse {
  width: 2.5rem;
  height: 5rem;
  border: 2px solid var(--accent-color);
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  display: flex;
}

.mouse span {
  width: 1rem;
  height: 1rem;
  background: var(--accent-color);
  display: block;
  border-radius: 50%;
  margin: auto;
  animation: move-wheel 2s linear infinite;
}

.arrow span {
  display: block;
  width: 1rem;
  height: 1rem;
  border: 2px solid transparent;
  border-right-color: var(--accent-color);
  border-bottom-color: var(--accent-color);
  transform: rotate(45deg);
  animation: arrow-down 1s alternate infinite;
}

@keyframes move-wheel {
  0% {
    opacity: 0;
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateY(1rem);
  }
}

@keyframes arrow-down {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.25;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 720px) {
  .scroll-down {
    display: none;
  }
}

/* 
Header CSS 
*/

.header--container {
  width: 100vw;
}

header {
  background-color: var(--tertiary-color);
  width: 100vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
  position: fixed;
  z-index: 500;
}

header ul {
  margin: 0;
  list-style: none;
}

header li {
  display: inline-block;
}

header ul a {
  text-decoration: none;
  color: inherit;
  padding: 6.8px;
  display: inline-block;
  transition: 0.5s;
}

header ul a:hover {
  background-color: rgba(70, 70, 70);
}

header h3 {
  font-size: 1rem;
  padding: 0 1rem 0;
  color: var(--primary-color);
}

header h3 + h3 {
  font-size: 1rem;
  color: var(--accent-color);
}

.header--toggle-button {
  width: 100%;
  margin-right: 1rem;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .header--nav {
    display: none;
  }
}

@media (min-width: 769px) {
  .header--toggle-button {
    display: none;
  }
}

/*
Hamburger Button
*/

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 20px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button--line {
  width: 100%;
  height: 2px;
  background: var(--accent-color);
}

/* 
SideDrawer
*/

.side-drawer--container {
  height: 100%;
  background: var(--secondary-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer--container.open {
  transform: translateX(0);
}

.side-drawer--container a:hover,
.side-drawer--container a:active {
  background: rgba(42, 45, 46);
}

.side-drawer--container ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 5rem 2rem;
  font-size: 2rem;
  gap: 1rem;
}

.side-drawer--container li {
}

.backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100;
}

@media (min-width: 769px) {
  .side-drawer--container {
    display: none;
  }
}

/* 
Aside CSS 
*/

aside {
  margin-top: 30rem;
  background-image: linear-gradient(rgba(30, 30, 30), rgba(60, 60, 60));
  width: 3rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: fixed;
  align-items: center;
}

.svg--bottom {
  padding-bottom: 35rem;
}

svg {
  stroke: var(--text-tertiary-accent);
  height: 24px;
  padding-bottom: 1rem;
  fill: none;
  transition: 0.4s;
}

svg:hover {
  stroke: var(--accent-color);
  fill: rgba(57, 167, 242, 0.25);
  transition: 0.4s;
}
.svg--bottom:hover {
  stroke: var(--text-tertiary-accent);
  fill: none;
  transition: 0.4s;
}

.main--container {
  width: 100%;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .aside--container {
    display: none;
  }
}

/* 
Hero CSS 
*/

.hero--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  max-width: 90%;
  text-align: left;
}

.hero--container h4 {
  color: var(--accent-color);
  padding-bottom: 2rem;
}

.hero--container h1 {
  padding-bottom: 1rem;
}

.hero--container h1 + h1 {
  color: var(--text-tertiary-accent);
  padding-bottom: 2rem;
}

.hero--container h2 {
  width: 100%;
  color: var(--text-tertiary-accent);
  padding-bottom: 2rem;
  font-size: 1rem;
}

.button {
  color: var(--primary-color);
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
  padding: 1rem;
}

.button {
  color: var(--primary-color);
  background: none;
  border: 1px solid var(--primary-color);
  border-radius: 2px;
  padding: 1rem;
}

.button:hover {
  background: rgba(45, 129, 184, 0.22);
  transition: 0.4s;
  cursor: pointer;
}

/* 
About CSS 
*/

.about h4 {
  padding-top: 4rem;
}

.about {
  font-size: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  max-height: 100vw;
  width: 90%;
}

.about--container {
  max-width: 50%;
  font-size: 1.5rem;
  display: flex;
  flex-flow: column wrap-reverse;
  text-align: left;
  padding: 4rem;
}

@media (max-width: 768px) {
  .about--container {
    max-width: 50%;
  }
  .about {
    max-height: none;
  }
}

.about--img img {
  max-width: 90%;
  border-radius: 50%;
  border: 0.25rem solid var(--accent-color);
  margin: 5rem 0;
}

.fa-brands {
  font-size: 2rem;
  margin-right: 1rem;
}

/* 
Projects CSS 
*/

#projects {
  padding-top: 4rem;
  max-width: 100%;
}

.projects--container {
  font-size: 1.5rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  width: 90%;
  margin-bottom: 25rem;
}

.projects--card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  max-width: 90%;
  min-height: 50vh;
}

.projects--img-border {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-height: 50vw;
  border: 0.25rem solid var(--accent-color);
  border-radius: 1px;
  background: var(--secondary-color);
  transition: 0.5s;
  margin: 1rem;
  height: 25rem;
  width: 25rem;
}

.projects--img-border:hover {
  border: 0.25rem solid var(--primary-color);
  background: var(--tertiary-color);
  transition: 0.5s;
}

.projects--img {
  height: 30rem;
  background: transparent;
}

.projects--img:hover {
  webkit-filter: blur(4px);
  filter: blur(4px);
}

.projects--info {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 500px;
  max-width: 90%;
  padding: 2rem;
}

.projects--info h4 {
  color: var(--text-second-accent);
  padding-bottom: 1rem;
}

.projects--icons {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
}

/* 
contact 
*/

#contact {
  height: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#contact > h4 {
  margin-bottom: 3rem;
}

/* 
Footer CSS 
*/

#footer {
  background: var(--accent-color);
  width: 100vw;
  height: 1.5rem;
  position: fixed;
  left: 0;
  bottom: 0;
}

.footer--container {
  display: flex;
  justify-content: end;
  align-content: center;
  align-items: center;
  padding-right: 0.5rem;
  font-size: 0.5rem;
}

.footer--container a {
  padding: 5px;
}

.footer--container a:hover {
  padding: 5px;
  background: var(--primary-color);
}
